<template>
  <section class="misc-wrapper">
    <!-- tabs navegacion -->
    <b-container class="mt-2 mb-5 justify-content-center col-12 col-md-8">
      <b-card>
        <b-card-body >
          <b-row class="mt-0">
            <b-col cols="12" class="d-flex align-items-center justify-content-center mb-4 mb-md-2">
              <img :src="require('@/assets/images/logo/Logo_TFC_Negro.png')" alt="logo" height="100rem">
            </b-col>
            <b-col cols="12" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
              <h3 v-if="language == '1'">Terms and Use</h3>
              <h3 v-else-if="language == '2'">Términos y Condiciones</h3>
            </b-col>

            <!-- <b-col cols="12" md="6">
              <b-card-text class="text-justify" v-html="termCondition.data.title" />
            </b-col> -->

            <b-col v-if="hasTermsConditions" cols="12" md="12" class="d-flex align-items-left justify-content-left mb-2 mb-md-0">
              <div class="ecommerce-details-terms d-flex flex-wrap mt-1">
                <div v-html="termCondition.content" class="mr-1"></div>
              </div>
            </b-col>

            <b-col v-else-if="loadingTermsConditions" cols="12" md="12" class="d-flex align-items-center justify-content-center mb-2 mb-md-0 mt-1">
              <b-spinner class="text-center"/>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
  </section>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  async mounted(){
    this.loadingTermsConditions = true
    this.termCondition = await this.fetchTermsConditions( {idTerm: this.$route.params.term, idLanguage: this.$route.params.lang, typeTerms: 3}  )
    this.loadingTermsConditions = false
    this.hasTermsConditions = Boolean(this.termCondition?.content)
  },

  data(){
    return {
      termCondition: null,
      loadingTermsConditions: false,
      hasTermsConditions: false,
      language: this.$route.params.lang
    }
  },
  computed:{
    ...mapState('auth',['user']),
  },
  methods:{
    ...mapActions('owners',['fetchTermsConditions']),
  }
}
</script>

<style>

iframe {
  overflow: hidden;
}

.containerIframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 619px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.alert.alert-danger {
  padding: 9px 0 7px 13px;
}
.container {
  padding-bottom: 110px;
}
.misc-wrapper {
  position: relative;
  height: 100%;
  display: contents;
}

.sidebar-head{
    position: relative;
    /* padding: 1rem; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;    
}
</style>